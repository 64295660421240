const Cookies = () => import(/* webpackChunkName: "cookies" */ /* webpackPrefetch: true */ '@/views/CookiesPage');

const cookies = [
	{
		path      : '/:var1/:var2/:var3/politica-cookies/:seller_id',
		component : Cookies,
	},
	{
		path      : '/:var1/:var2/politica-cookies/:seller_id',
		component : Cookies,
	},
	{
		path      : '/:var1/politica-cookies/:seller_id',
		component : Cookies,
	},
	{
		path      : '/politica-cookies/:seller_id',
		component : Cookies,
	}
];

export default cookies;
