export default {
	install(Vue, options) {
		Vue.prototype.helpers = {
			/**
			 *
			 * @param setup
			 * @returns {string|*}
			 */
			setPrivacyText(setup) {
				if (setup.privacy_name !== null && setup.privacy_email !== null && setup.privacy_address !== null) {
					return `Os seus dados pessoais serão tratados pela ${setup.privacy_name} na qualidade de Responsável pelo Tratamento. Tem o direito de solicitar o acesso à sua informação ou requerer uma cópia da mesma,
 						bem como, pode solicitar que esta seja rectificada. Pode, ainda, requerer a limitação do tratamento dos seus dados ou opor-se ao tratamento dos seus dados pessoais, sem qualquer justificação,
 						 quando esteja em causa uma situação de marketing directo e, nos restantes casos, por motivos relacionados com a sua situação em particular. Para exercer os seus direitos, ou para mais informações sobre 
 						 como os seus dados pessoais são tratados pela ${setup.privacy_name}, deverá contactar através do e-mail <a href="mailto:${setup.privacy_email}">${setup.privacy_email}</a> ou de uma carta para ${setup.privacy_address} fornecendo um comprovativo da sua identidade.
                        O consentimento pode ser retirado a qualquer momento, o que não torna ilegítimo o tratamento de dados pessoais realizado com base nesse consentimento, até à data em que o mesmo seja retirado.`;
				}

				return setup.campaign_privacy !== null ? setup.campaign_privacy : '';

			},

			/**
			 *
			 * @param sellerId
			 * @param cookiesText
			 * @returns {string|*}
			 */
			setCookiesText : function (sellerId, cookiesText, config) {
				const defaultText = `Utilizamos cookies para melhorar a sua experiência no nosso website, os nossos serviços e
									 proporcionar uma experiência mais segura. Recomendamos que leia a nossa 
									 <a href="${config.full_url}/politica-cookies/${sellerId}" target="_blank" rel="noopener">Política de Cookies</a>
									  para obter mais informação ou saber como alterar a configuração do browser.`;

				return cookiesText === null ? defaultText : cookiesText;

			},

			/**
			 *
			 * @param config
			 */
			setMetas(config) {
				let metas = document.getElementsByTagName('meta');
				for (let i = 0; i < metas.length; i++) {
					if (metas[i].getAttribute('name') === 'description') {
						metas[i].content = config.meta_description;
					} else if (metas[i].getAttribute('name') === 'robots') {
						metas[i].content = config.robots_index ? 'index' : 'noindex';
					}
				}
			}
		};
	}
};
