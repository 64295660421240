const Terms = () => import(/* webpackChunkName: "terms" */ /* webpackPrefetch: true */ '@/views/TermsPage');

const terms = [
	{
		path      : '/:var1/:var2/:var3/termos-condicoes/:seller_id',
		component : Terms,
	},
	{
		path      : '/:var1/:var2/termos-condicoes/:seller_id',
		component : Terms,
	},
	{
		path      : '/:var1/termos-condicoes/:seller_id',
		component : Terms,
	},
	{
		path      : '/termos-condicoes/:seller_id',
		component : Terms,
	},
];

export default terms;
