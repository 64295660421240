import axios from 'axios';
import Vue   from 'vue';
import Vuex  from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	state     : {
		resData            : {},
		isLoading          : false,
		currentUrl         : '',
		listingData        : {},
		listingId          : null,
		listingLocationId  : null,
		debugMode          : false,
		testLead           : false,
		fillForm           : false,
		disableSendMail    : false,
		gtmTrigger         : false,
		formSubmitToServer : false,
		fatForm            : false,
		showSuccessForm    : false,
		formPlacement      : '',
		emailToSend        : '',
		templateToSend     : '',
		mailerToUse        : '',
		alid               : '',
		formVersion        : {
			version : ''
		},
		jsonConfig         : {}
	},
	mutations : {
		setJsonConfig(state, data) {
			state.jsonConfig = data;
		},
		getResponse(state, data) {
			state.resData = data;
		},
		setAlId(state, alid) {
			state.alid = alid;
		},
		setFormVersion(state, data) {
			state.formVersion = data;
		},
		setUrl(state, url) {
			state.currentUrl = url;
		},
		getListingData(state, data) {
			state.listingData = data;
		},
		setListingId(state, data) {
			state.listingId = data;
		},
		setListingLocationId(state, data) {
			state.listingLocationId = data;
		},
		setDebugMode(state, data) {
			state.debugMode = data;
		},
		setTestLead(state, data) {
			state.testLead = data;
		},
		setFillForm(state, data) {
			state.fillForm = data;
		},
		setDisableSendMail(state, data) {
			state.disableSendMail = data;
		},
		setCancelGtmTrigger(state, data) {
			state.gtmTrigger = data;
		},
		setFormSubmitToServer(state, data) {
			state.formSubmitToServer = data;
		},
		setSuccessForm(state, data) {
			state.showSuccessForm = data;
		},
		setFatForm(state, data) {
			state.fatForm = data;
		},
		setFormPlacement(state, data) {
			state.formPlacement = data;
		},
		setEmailToSend(state, data) {
			state.emailToSend = data;
		},
		setTemplateToSend(state, data) {
			state.templateToSend = data;
		},
		setMailerToUse(state, data) {
			state.mailerToUse = data;
		}
	},
	actions   : {
		testLead({ commit }, val) {
			commit('setTestLead', val);
		},
		fillForm({ commit }, val) {
			commit('setFillForm', val);
		},
		disableSendMail({ commit }, val) {
			commit('setDisableSendMail', val);
		},
		cancelGtmTrigger({ commit }, val) {
			commit('setCancelGtmTrigger', val);
		},
		cancelFormSubmitToServer({ commit }, val) {
			commit('setFormSubmitToServer', val);
		},
		fatForm({ commit }, val) {
			commit('setFatForm', val);
		},
		successForm({ commit }, val) {
			commit('setSuccessForm', val);
		},
		formPlacement({ commit }, val) {
			commit('setFormPlacement', val);
		},
		emailToSend({ commit }, val) {
			commit('setEmailToSend', val);
		},
		templateToSend({ commit }, val) {
			commit('setTemplateToSend', val);
		},
		mailerToUse({ commit }, val) {
			commit('setMailerToUse', val);
		},
		formVersion({ commit }, val) {
			commit('setFormVersion', val);
		},
		async getDebugMode({ commit }, debug) {
			commit('setDebugMode', debug);
		},
		async getListingLocationId({ commit }, id) {
			commit('setListingLocationId', id);
		},
		async getListingId({ commit }, id) {
			commit('setListingId', id);
		},
		async getAlId({ commit }, alid) {
			commit('setAlId', alid);
		},
		async getJsonConfig({ commit }, data) {
			commit('setJsonConfig', data);
		},
		async getValidLp({ commit }, obj) {
			commit('setUrl', obj.url);
			commit('getResponse', obj.res);
		},
		async getListingResponseData({ commit }, { sellers, listingId, catalogId }) {
			let res = await axios.get(`${process.env.VUE_APP_API_URL}/api/ad-engine/landing-page/listings/${listingId}?sellers=${sellers}&catalog_id=${catalogId}`, {
				headers : {
					'Content-Type' : 'application/json',
					'Accept'       : 'application/json'
				}
			});

			commit('getListingData', res, { root : true });
		}
	}
});

export default store;
