import catalogs from "@/router/catalogs";
import cookies from "@/router/cookies";
import home from "@/router/home";
import privacy from "@/router/privacy";
import terms from "@/router/terms";
import axios from "axios";
import Vue from "vue";
import VueMeta from "vue-meta";
import VueRouter from "vue-router";
import store from "../../store";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...privacy, ...cookies, ...terms, ...catalogs, ...home],
});

router.beforeEach(async (to, from, next) => {
  // let currentUrl = window.location.href;
  let currentUrl = window.location.origin;
  currentUrl +=
    window.location.pathname.substr(window.location.pathname.length - 1) === "/"
      ? window.location.pathname.substring(
          0,
          window.location.pathname.length - 1
        )
      : window.location.pathname;
  currentUrl += window.location.search;
  // currentUrl += window.location.hash;

  let substrings = [
    "politica-privacidade",
    "politica-cookies",
    "termos-condicoes",
    "viaturas",
  ];
  let fullUrl = currentUrl;
  let isStock = false;

  if (substrings.some((s) => currentUrl.includes(s))) {
    if (currentUrl.match(/(:\/\/viaturas|viaturas-|-viaturas-)/) !== null) {
      if ((fullUrl.match(/viaturas/g) || []).length > 1) {
        substrings.forEach((e) => {
          if (currentUrl.indexOf(e) > -1) {
            fullUrl = currentUrl.substring(0, currentUrl.lastIndexOf(e));
            if (e === "viaturas") {
              isStock = true;
            }
          }
        });
      }
    } else {
      substrings.forEach((e) => {
        if (currentUrl.indexOf(e) > -1) {
          fullUrl = currentUrl.substring(0, currentUrl.lastIndexOf(e));
          if (e === "viaturas") {
            isStock = true;
          }
        }
      });
    }
  }

  let cleanUrl = fullUrl.split("?")[0];
  let urlToSend =
    cleanUrl.substr(cleanUrl.length - 1) === "/"
      ? cleanUrl.substring(0, cleanUrl.length - 1)
      : cleanUrl;

  let url = `${process.env.VUE_APP_API_URL}/api/ad-engine/landing-page/config?full_url=${urlToSend}&is_stock=${isStock}`;
  if (to.query.hasOwnProperty("preview")) {
    let filterInt = function (value) {
      if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value);
      return null;
    };
    if (filterInt(to.query.preview) == null) {
      url += "&preview=true";
    } else if (typeof filterInt(to.query.preview) === "number") {
      url += `&preview=${to.query.preview}`;
    }
  }

  try {
    let res = await axios.get(`${url}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    store.dispatch("getValidLp", { url: urlToSend, res });
    next();
  } catch (error) {
    if (process.env.NODE_ENV !== "development") {
      window.location = error.response.data.message.redirect_url;
      return;
    }
    console.log(error);
  }
});

export default router;
