<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>

import AOS from 'aos';

export default {
	name : 'App',
	created() {
		AOS.init({
			disable  : window.innerWidth > 768,
			easing   : 'ease-out-back',
			duration : 1000
		});
	},
};
</script>

<style></style>
