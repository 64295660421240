import App                                                     from '@/App';
import helpers                                                 from '@/plugins/helpers';
import router                                                  from '@/router/index';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import {BTooltip, CardPlugin, CollapsePlugin, VBTooltipPlugin, BSidebar} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'core-js/stable';
import 'es6-promise/auto';
import 'intersection-observer'; // Optional
import 'regenerator-runtime/runtime';
import Vue                                                     from 'vue';
import VueCookies                                              from 'vue-cookies';
import vueDebounce                                             from 'vue-debounce';
import InfiniteLoading                                         from 'vue-infinite-loading';
import VueLazyload                                             from 'vue-lazyload';
import VueObserveVisibility                                    from 'vue-observe-visibility';
import Vue2Filters                                             from 'vue2-filters';
import store                                                   from '../store';


require('intersection-observer');
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(vueDebounce, {
	listenTo : ['input', 'keyup', 'select'],
});
Vue.use(VueCookies);
Vue.use(VBTooltipPlugin);
Vue.use(VueObserveVisibility);
Vue.config.productionTip = false;
Vue.use(Vue2Filters);
Vue.component('BTooltip', BTooltip);
Vue.component('b-sidebar', BSidebar)

Vue.use(InfiniteLoading, {
	slots  : {
		noMore : '',
	},
	props  : {
		spinner : 'spiral',
	},
	system : {
		throttleLimit : 1500,
	},
});
Vue.use(VueLazyload);
Vue.use(helpers);
Vue.prototype.$eventBus = new Vue();

new Vue({
	router,
	store,
	render : h => h(App)
}).$mount('#app');
