<template>
  <div class="main">
    <div id="status-bar"></div>
    <base-template
      :response="response"
      v-if="template === 'base' && isLoaded"
    ></base-template>
    <base-2022-template
      :response="response"
      v-if="template === 'base_2022' && isLoaded"
    ></base-2022-template>
    <alpine-template
      :response="response"
      v-if="template === 'alpine' && isLoaded"
    ></alpine-template>
    <template-estilo-peugeot
      :response="response"
      v-if="template === 'template_estilo_peugeot' && isLoaded"
    ></template-estilo-peugeot>
    <VueScriptComponent
      v-if="
        isLoaded === true &&
        (response.setup.seller_id == 159 || response.setup.seller_id == 442)
      "
      script='<script type="text/javascript">
      if(window._mtmReady){ 
      console.error("Connected sites script already loaded. You might have it dupplicated."); 
      } else { 
      window._mtmReady=true; 
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
      var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
      g.async=true; g.src="https://egoi.site/1464356_lubrigaz.pt.js"; 
      s.parentNode.insertBefore(g,s);
      } </script>'
    />
    <VueScriptComponent
      v-if="isLoaded === true && response.setup.seller_id == 182"
      script='<script type="text/javascript">
      if(window._mtmReady){ 
      console.error("Connected sites script already loaded. You might have it dupplicated."); 
      } else { 
      window._mtmReady=true; 
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
      var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
      g.async=true; g.src="https://egoi.site/1464356_lubrisport.pt.js"; 
      s.parentNode.insertBefore(g,s);
      } </script>'
    />
    <VueScriptComponent
      v-if="isLoaded === true && response.setup.seller_id == 593"
      script='<script type="text/javascript">
      if(window._mtmReady){ 
      console.error("Connected sites script already loaded. You might have it dupplicated."); 
      } else { 
      window._mtmReady=true; 
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
      var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
      g.async=true; g.src="https://egoi.site/1464356_ducatileiria.pt.js"; 
      s.parentNode.insertBefore(g,s);
      } </script>'
    />
  </div>
</template>

<script>
import {v4 as uuidv4}     from 'uuid';
import VueScriptComponent from 'vue-script-component';

export default {
	name       : 'Index',
	components : {
		BaseTemplate          : () => import('@/templates/Base'),
		Base2022Template      : () => import('@/templates/Base2022'),
		AlpineTemplate        : () => import('@/templates/Alpine'),
		CatalogV1             : () => import('@/templates/CatalogV1'),
		CatalogV2             : () => import('@/templates/CatalogV2'),
		TemplateEstiloPeugeot : () => import('@/templates/TemplateEstiloPeugeot'),
		VueScriptComponent,
	},
	data() {
		return {
			response : Object,
			isLoaded : false,
			template : '',
		};
	},
	async mounted() {
		this.response = this.$store.state.resData.data;
		this.template = this.response.template;
		this.isLoaded = true;

		let alid = uuidv4();
		await this.$store.dispatch('getAlId', alid);

		window.alp.init(this.response.setup.fb_pixel_id, alid);
		window.alp.trackEvent({ event : 'PageView' });

		let fullUrl = window.location.href;
		if (fullUrl.match(/(:\/\/viaturas|viaturas-|-viaturas-)/) !== null) {
			this.checkIsStockListing(fullUrl, 1);
		} else {
			this.checkIsStockListing(fullUrl, 0);
		}
	},
	methods : {
		checkIsStockListing(fullUrl, length) {
			if (
				(fullUrl.match(/viaturas/g) || []).length > length &&
				!this.$route.params.hasOwnProperty('listing_id')
			) {
				if (process.env.NODE_ENV !== 'development') {
					window.location = this.response.redirect_url;
					return;
				}
				console.log('failed. redirecting -> ' + this.response.redirect_url);
			}
		},
	},
};
</script>
