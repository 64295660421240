<template>
	<div class="main">
		<div id="status-bar"></div>
		<catalog-v1 :response="response" v-if="template === 'catalog_v1' && isLoaded"></catalog-v1>
		<catalog-v2 :response="response" v-if="template === 'catalog_v2' && isLoaded"></catalog-v2>
		<catalog-v1-refresh :response="response"
							v-if="template === 'catalog_v1_refresh' && isLoaded"></catalog-v1-refresh>
		<catalog-v2-refresh :response="response"
							v-if="template === 'catalog_v2_refresh' && isLoaded"></catalog-v2-refresh>
		<VueScriptComponent
			v-if="isLoaded === true &&(response.setup.seller_id == 159 || response.setup.seller_id == 442)"
			script='<script type="text/javascript">
		if(window._mtmReady){
		console.error("Connected sites script already loaded. You might have it dupplicated.");
		} else {
		window._mtmReady=true;
		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
		var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
		g.async=true; g.src="https://egoi.site/1464356_lubrigaz.pt.js";
		s.parentNode.insertBefore(g,s);
		} </script>'
		/>
	</div>
</template>

<script>

import {v4 as uuidv4}     from 'uuid';
import VueScriptComponent from 'vue-script-component';

export default {
	name       : 'Catalogs',
	components : {
		CatalogV1        : () => import('@/templates/CatalogV1'),
		CatalogV2        : () => import('@/templates/CatalogV2'),
		CatalogV1Refresh : () => import('@/templates/CatalogV1Refresh'),
		CatalogV2Refresh : () => import('@/templates/CatalogV2Refresh'),
		VueScriptComponent
	},
	data() {
		return {
			response : Object,
			isLoaded : false,
			template : '',
		};
	},
	async mounted() {
		this.response = this.$store.state.resData.data;
		this.template = this.response.template;
		this.isLoaded = true;

		let alid = uuidv4();
		await this.$store.dispatch('getAlId', alid);

		window.alp.init(this.response.setup.fb_pixel_id, alid);
		window.alp.trackEvent({ 'event' : 'PageView' });

		let fullUrl = window.location.href;
		if ((fullUrl.match(/viaturas/g) || []).length > 1 && !this.$route.params.hasOwnProperty('listing_id')) {
			if (process.env.NODE_ENV !== 'development') {
				window.location = this.response.redirect_url;
				return;
			}

			console.log('failed');
		}
	},
};
</script>
