// const Index = () => import(/* webpackChunkName: "index" */ '@/views/Index');
import Index from '@/views/Index';

const home = [
	{
		path      : '/:var1/:var2/:var3/:var4/:var5',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	},
	{
		path      : '/:var1/:var2/:var3/:var4',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	},
	{
		path      : '/:var1/:var2/:var3',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	},
	{
		path      : '/:var1/:var2',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	},
	{
		path      : '/:var1',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	},
	{
		path      : '/',
		component : Index,
		meta      : { gtm : 'Homepage LP' },
	}
];

export default home;
