const PrivacyPage = () => import(/* webpackChunkName: "privacy" */  /* webpackPrefetch: true */ '@/views/PrivacyPage');

const privacy = [
	{
		path      : '/:var1/:var2/:var3/politica-privacidade/:seller_id',
		component : PrivacyPage,
	},
	{
		path      : '/:var1/:var2/politica-privacidade/:seller_id',
		component : PrivacyPage,
	},
	{
		path      : '/:var1/politica-privacidade/:seller_id',
		component : PrivacyPage,
	},
	{
		path      : '/politica-privacidade/:seller_id',
		component : PrivacyPage,
	}
];

export default privacy;
