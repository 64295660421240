// const Catalogs = () => import(/* webpackChunkName: "catalogs" */ '@/views/Catalogs');
import Catalogs from '@/views/Catalogs';

const catalogs = [
	// models
	{
		path      : '/:var1/:var2/:var3/viaturas/:make/:model/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/:var2/viaturas/:make/:model/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/viaturas/:make/:model/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/viaturas/:make/:model/:listing_id',
		component : Catalogs,
	},
	// makes
	{
		path      : '/:var1/:var2/:var3/viaturas/:make/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/:var2/viaturas/:make/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/viaturas/:make/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/viaturas/:make/:listing_id',
		component : Catalogs,
	},
	// viaturas base
	{
		path      : '/:var1/:var2/:var3/viaturas/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/:var2/viaturas/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/:var1/viaturas/:listing_id',
		component : Catalogs,
	},
	{
		path      : '/viaturas/:listing_id',
		component : Catalogs,
	},
];

export default catalogs;
